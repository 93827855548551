import React from "react"

import styled from "styled-components"
import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import colors from "../utils/colors"
import { Container } from "../utils/common-styles"
import ogImage from "../images/image_rekryterautvecklare.png"

const PostTitle = styled.h3`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${colors.DARK_BLUE};
`

const Policy = ({ path }) => (
  <LayoutPage
    path={path}
    title="Integritetspolicy"
    subtitle="Behandling av personuppgifter på Rekryterautvecklare.se"
  >
    <SEO title="Privacy policy" description="Privacy policy" image={ogImage} />
    <Container>
      <p>
        För Rekryterautvecklare.se är personlig integritet viktigt. Vi
        eftersträvar en hög nivå av dataskydd. I denna policy förklarar vi hur
        vi samlar in och använder personuppgifter. Vi beskriver också dina
        rättigheter och hur du kan göra dem gällande.
      </p>
      <p>
        Du är alltid välkommen att kontakta oss om du har frågor om hur vi
        behandlar dina personuppgifter. Kontaktuppgifter står sist i denna text.
      </p>
      <h4>
        {" "}
        Vad är en personuppgift och vad är en behandling av personuppgifter?
      </h4>
      <p>
        Personuppgifter är alla uppgifter om en levande fysisk person som direkt
        eller indirekt kan kopplas till den personen. Det handlar inte bara om
        namn och personnummer utan även om till exempel bilder och
        e-postadresser.
      </p>
      <p>
        Behandling av personuppgifter är allt som sker med personuppgifterna i
        IT-systemen, oavsett om det handlar om mobila enheter eller datorer. Det
        handlar om till exempel insamling, registrering, strukturering, lagring,
        bearbetning och överföring. I vissa fall kan även manuella register
        omfattas.
      </p>
      <h4>Personuppgiftsansvarig</h4>
      <p>
        För de behandlingar som sker inom Rekryterautvecklare.se verksamhet är
        Rekryterautvecklare.se personuppgiftsansvarig.
      </p>
      <h4>Vilka personuppgifter samlar vi in om dig och varför?</h4>
      <p>Vi behandlar i huvudsak ditt namn och kontaktuppgifter.</p>
      <h4>Personuppgiftsbiträden</h4>
      <p>
        I en del situationer är det nödvändigt för oss att anlita andra parter.
        De är då personuppgiftsbiträden till oss. Vi kontrollerar
        personuppgiftsbiträden för att säkerställa att de garanterar säkerheten
        och sekretessen för personuppgifterna. När personuppgiftsbiträden
        anlitas sker det bara för de ändamål som är förenliga med de ändamål vi
        själva har för behandlingen.
      </p>
      <h4>Aktörer som är självständigt personuppgiftsansvariga</h4>
      <p>
        Vi delar även dina personuppgifter med vissa andra aktörer som är
        självständigt personuppgiftsansvariga, till exempel myndigheter som
        Skatteverket, när vi är skyldiga att lämna ut sådana uppgifter med stöd
        av lag eller myndighetsbeslut. När dina personuppgifter delas med en
        aktör som är självständigt personuppgiftsansvarig gäller den
        organisationens integritetspolicy och personuppgiftshantering.
      </p>
      <h4>Hur länge sparar vi dina personuppgifter?</h4>
      <p>
        Vi sparar aldrig dina personuppgifter längre än vad som är nödvändigt
        för respektive ändamål. Vissa uppgifter i bokföringen behöver på grund
        av lagstiftning till exempel sparas minst sju år.
      </p>
      <h4>Vad är dina rättigheter som registrerad?</h4>
      <p>
        Som registrerad har du enligt gällande lagstiftning ett antal
        rättigheter. Du har rätt till att få ett utdrag som visar vilka
        personuppgifter vi har registrerade om dig. Du kan begära rättelse av
        felaktiga uppgifter och i vissa fall radering.
      </p>
      <h4>Kontakta oss vid frågor om hur vi behandlar personuppgifter.</h4>
      <p>
        Om du har frågor om hur vi behandlar personuppgifter{" "}
        <a href="/contact/">kontakta oss</a>.
      </p>
      <p>
        Vi kan komma att göra ändringar i vår integritetspolicy. Den senaste
        versionen av integritetspolicyn finns alltid här på webbplatsen.
      </p>{" "}
    </Container>
  </LayoutPage>
)

export default Policy
